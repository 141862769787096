export const Skill = [
  'スキルなし',
  'KO術',
  'アイテム使用強化',
  'ガード強化',
  'ガード性能',
  'キノコ大好き',
  'ジャンプ鉄人',
  'スタミナ急速回復',
  'スタミナ奪取',
  'ひるみ軽減',
  'フルチャージ',
  'ブレ抑制',
  'ボマー',
  'ランナー',
  '火事場力',
  '火属性攻撃強化',
  '火耐性',
  '会心撃【属性】',
  '回避距離UP',
  '回避性能',
  '回復速度',
  '滑走強化',
  '貫通弾・貫通矢強化',
  '気絶耐性',
  '鬼火纏',
  '逆恨み',
  '逆襲',
  '強化持続',
  '業物',
  '見切り',
  '幸運',
  '広域化',
  '攻めの守勢',
  '攻撃',
  '高速変形',
  '剛刃研磨',
  '散弾・拡散矢強化',
  '死中に活',
  '耳栓',
  '弱点特効',
  '集中',
  '匠',
  '乗り名人',
  '植生学',
  '心眼',
  '水属性攻撃強化',
  '水耐性',
  '睡眠属性強化',
  '睡眠耐性',
  '精霊の加護',
  '早食い',
  '装填拡張',
  '装填速度',
  '速射強化',
  '属性やられ耐性',
  '体術',
  '体力回復量UP',
  '耐震',
  '達人芸',
  '弾丸節約',
  '弾導強化',
  '地質学',
  '挑戦者',
  '超会心',
  '通常弾・連射矢強化',
  '泥雪耐性',
  '笛吹き名人',
  '砥石使用高速化',
  '特殊射撃強化',
  '毒属性強化',
  '毒耐性',
  '鈍器使い',
  '納刀術',
  '破壊王',
  '剥ぎ取り鉄人',
  '爆破やられ耐性',
  '爆破属性強化',
  '抜刀術【技】',
  '抜刀術【力】',
  '反動軽減',
  '飛び込み',
  '氷属性攻撃強化',
  '氷耐性',
  '不屈',
  '風圧耐性',
  '腹減り耐性',
  '壁面移動',
  '泡沫の舞',
  '砲術',
  '砲弾装填',
  '防御',
  '麻痺属性強化',
  '麻痺耐性',
  '満足感',
  '陽動',
  '雷属性攻撃強化',
  '雷耐性',
  '龍属性攻撃強化',
  '龍耐性',
  '力の解放',
  '渾身',
  '翔蟲使い',
];

export const LackSkill = [
  '弓溜め段階解放',
  '霞皮の恩恵',
  '鋼殻の恩恵',
  '炎鱗の恩恵',
  '風紋の一致',
  '雷紋の一致',
  '風雷合一',
  '龍気活性',
  '捕獲名人',
  '剥ぎ取り名人',
];

export const Level = [
  '1',
  '2',
  '3',
  '4',
];

export const LackLevel = [
  '5',
  '6',
  '7',
];

export const Slot = [
  '1',
  '2',
  '3',
];

export const LackSlot = [

];
